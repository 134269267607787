var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level mt-4"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.organization.name) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('ul', {
    staticClass: "nav nav-tabs"
  }, [_vm.organization_type == 'Awaris' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'organization.info' || this.$route.name == 'organization.edit' ? 'active' : '',
    attrs: {
      "to": {
        name: 'organization.info',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav1")))])], 1) : _vm.organization_type == 'Individu' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'organization.info-individual' || this.$route.name == 'organization.edit' ? 'active' : '',
    attrs: {
      "to": {
        name: 'organization.info-individual',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav5")))])], 1) : _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'organization.info' || this.$route.name == 'organization.edit' ? 'active' : '',
    attrs: {
      "to": {
        name: 'organization.info',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav5")))])], 1), _vm.organization_type != 'Individu' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'organization.branch' || this.$route.name == 'organization.branch.create' || this.$route.name == 'organization.branch.info' || this.$route.name == 'organization.branch.edit' ? 'active' : '',
    attrs: {
      "to": {
        name: 'organization.branch',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))])], 1) : _vm._e(), _vm.organization_type != 'Individu' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'organization.user' || this.$route.name == 'organization.user.create' || this.$route.name == 'organization.user.info' || this.$route.name == 'organization.user.edit' ? 'active' : '',
    attrs: {
      "to": {
        name: 'organization.user',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.user")))])], 1) : _vm._e(), _vm.userRole != 'IT' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'organization.pricing' || this.$route.name == 'organization.pricing.create' || this.$route.name == 'organization.pricing.info' || this.$route.name == 'organization.pricing.edit' ? 'active' : '',
    attrs: {
      "to": {
        name: 'organization.pricing',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav3")))])], 1) : _vm._e(), _vm.userRole != 'IT' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: ['organization.product.index'].includes(this.$route.name) || ['organization.product.wasiat.edit'].includes(this.$route.name) || ['organization.product.hibah.edit'].includes(this.$route.name) || ['organization.product.trust.edit'].includes(this.$route.name) ? 'active' : '',
    attrs: {
      "to": {
        name: 'organization.product.index',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.product")))])], 1) : _vm._e()]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {}, [_c('router-view', {
    attrs: {
      "organization": _vm.organization,
      "userRole": _vm.userRole
    }
  })], 1)])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }